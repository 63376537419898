
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface ICancellationActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "billingActivitiesView",
  mixins: [ActivitiesMixin],
  data(): ICancellationActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          DELETE_BULK_CANCELLATION_CHANGE_REQUEST_SUCCESS,
          DELETE_BULK_CANCELLATION_CHANGE_REQUEST_FAILURE,
          UPDATE_CANCELLATION_CHANGE_REQUEST_SUCCESS,
          UPDATE_CANCELLATION_CHANGE_REQUEST_FAILURE,
          ADD_CANCELLATION_CHANGE_REQUEST_SUCCESS,
          ADD_CANCELLATION_CHANGE_REQUEST_FAILURE,
          ADD_CANCELLATION_CHANGE_REQUEST_SUCCESS,
          ADD_CANCELLATION_CHANGE_REQUEST_FAILURE,
          LASER_FICHE_FILE_UPLOAD_FAILURE,
          LASER_FICHE_FILE_UPLOAD_SUCCESS
          `.replace(/\s/g, ""),
        activityType: "CancellationChangeRequestActivity"
      };
    }
  }
});
